/**
 * Represents the params that could be applied for list views
 */
export interface ListingParams {
    listType: ListingListType,
    page: number,
    filter: {[key: string]: number[] | string[]},
    sorting: ListingListSorting | null,
}

/**
 * Represents the response object for async listing requests
 */
export interface AsyncListingResponse {
    success: boolean,
    count: number,
    markup: string[],
    remaining: number,
}

export interface FilterCountResponse {
    success: boolean,
    result?: {[key: string]: number},
    message?: string,
}

/**
 * Represents the params for fetching a new page
 */
export interface FetchPageParams {
    section: string,
    page: number,
    path: string,
    filter: object,
    listType: ListingListType,
    sorting: ListingListSorting | null,
}

/**
 * Represents the listing view mode
 */
export enum ListingListType {
    List = 'list',
    Grid = 'grid',
}

/**
 * Represents the rendering mode for the listing
 */
export enum ListingRenderMode {
    Append = 'append',
    Replace = 'replace',
}

/**
 * Represents the available sorting modes
 */
export enum ListingListSorting {
    Asc = 'asc',
    Desc = 'desc',
}