import $ from 'cash-dom';
import {subscribe} from '../humans/helper';

/**
 * Lazy Image component
 * @package AWW
 * @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
 */
export default class ImgFade {
    /**
     * UI references
     */
    protected ui = {
        generalFadeImages: '.media-box--img-fade img',
        modalFadeImages: '.modal .media-box--img-fade img',
        listingFadeImages: '.media-box--img-fade:not(.media-box--img-fade-loaded) img',
    };

    /**
     * Constructor
     */
    public constructor() {
        this.registerEvents();
    }

    /**
     * Register required events
     */
    protected registerEvents(): void {
        // Look for fade images and setup
        this.setupImages(this.ui.generalFadeImages);

        // Setup modal images
        subscribe('modal.content-loaded', () => {
            this.setupImages(this.ui.modalFadeImages);
        });

        // Setup infinite scroll images
        subscribe('listing.updated', () => {
            this.setupImages(this.ui.listingFadeImages);
        });
    }

    /**
     * Applies the fade logic for all images, that matches the given selector
     */
    protected setupImages(selector: string): void {
        const $images = $(selector);
        $images.each((_, image) => {
            if (image instanceof HTMLImageElement && !image.complete) {
                this.setupImg(image);
            }
        });
    }

    /**
     * Setup fade images
     */
    protected setupImg(img: HTMLImageElement): void {
        const $img = $(img);
        $img.parents('.media-box').addClass('media-box--img-fade-waiting');
        $img.one('load', this.fadeImageLoad.bind(this));
        $img.one('error', this.fadeImageError.bind(this));
    }

    /**
     * Handle fade image loading
     */
    protected fadeImageLoad(event: Event): void {
        const $img = $(event.currentTarget as HTMLImageElement);
        const $mediaBox = $img.parents('.media-box');
        if ($mediaBox.length > 0) {
            $mediaBox.removeClass('media-box--img-fade-waiting')
                .addClass('media-box--img-fade-loaded');
        }

        // Remove error callback
        $img.off('error');
    }

    /**
     * Handle fade image error
     */
    protected fadeImageError(event: Event): void {
        const $img = $(event.currentTarget as HTMLImageElement);
        const $mediaBox = $img.parents('.media-box');
        if ($mediaBox.length > 0) {
            $mediaBox.removeClass('media-box--img-fade-waiting')
                .addClass('media-box--img-fade-error');
        }

        // Remove load callback
        $img.off('load');
    }
}
