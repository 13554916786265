import {onReady, subscribe} from '../humans/helper';
import $, {Cash} from 'cash-dom';

export default class Contact {
    /**
     * Cash body reference
     */
    protected $body: Cash;

    /**
     * UI references
     */
    protected ui = {
        mailLinks: ".js-contact-link",
    };

    /**
     * Constructor
     */
    public constructor() {
        this.$body = $('body');

        this.registerEvents();
    }

    /**
     * Register required events
     */
    protected registerEvents(): void {
        onReady(this.decryptEmails.bind(this));

        // Setup mails in async loaded lists
        subscribe('listing.updated', () => {
            onReady(this.decryptEmails.bind(this));
        });
    }

    /**
     * Descrypt Emails and output
     */
    protected decryptEmails(): void {
        $(this.ui.mailLinks).each((index: number, element: any) => {
            // Decrypt mail and set href and inner text
            const decryptedMail = atob(element.dataset.encrMail);

            element.href = `mailto:${decryptedMail}`;
            element.querySelector('.js-contact-inner').innerText = decryptedMail;

            // Remove class name to prevent re-decrypt after async loading
            $(element).removeClass('js-contact-link');

            // Display element
            element.setAttribute('aria-hidden', 'false');
        });
    }
}
