import $ from 'cash-dom';
import PubSub from 'pubsub-js';
import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';
import {HistoryState} from '../aww/types/history';

/**
 * Helper method to invoke a callback once ready event was triggered
 * Callback is immediately invoked if DOM is already ready
 */
function onReady (callback: CallableFunction): void {
    $(callback);
}

/**
 * Helper method to invoke a callback once load event was triggered
 */
function onLoad (callback: CallableFunction): void {
    $(window).on('load', () => {
        callback();
    });
}

/**
 * Helper method that invokes the callback when browser is on idle
 */
function onIdle(callback: IdleRequestCallback): void {
    if ('requestIdleCallback' in window) {
        window.requestIdleCallback(callback);
        return;
    }

    window.setTimeout(callback, 1);
}

/**
 * Returns the rem value for the given pixel value
 * @param {number} pxSize The pixel value
 * @return {number} The rem value
 */
function pxToRem(pxSize: number): number {
    const fontSize: number = Number($('html')?.css('fontSize')?.replace('px', '') ?? 1);

    return Math.round(pxSize / fontSize * 100) / 100;
}

/**
 * Returns the pixel value for the given rem value
 * @param {number} remSize The rem value
 * @return {number} The pixel value
 */
function remToPx(remSize: number): number {
    const fontSize = Number($('html')?.css('fontSize')?.replace('px', '') ?? 1);

    return fontSize * remSize;
}

/**
 * Publishes the topic, passing the data to it's subscribers
 * @param {string} topic The topic for publishing [Eg. history.push-state]
 * @param {any}    data  The data that should be published
 */
function publish(topic: string, data: any = null): boolean {
    const publishTopic = topic.toLowerCase().replace(/\//g, '.');

    return PubSub.publishSync(publishTopic, data);
}

/**
 * Subscribes the passed function to the passed topic.
 * Every returned token is unique and should be stored if you need to unsubscribe
 * @param {string} topic The topic to subscribe to
 * @param {Function} callback The function to call when a new message is published
 * @return {Token} The token for the subscription
 */
function subscribe(topic: string, callback: Function | PubSubJS.SubscriptionListener<any>): PubSubJS.Token {
    const subscribeTopic = topic.toLowerCase().replace(/\//g, '.');

    return PubSub.subscribe(subscribeTopic, callback as PubSubJS.SubscriptionListener<any>);
}


/**
 * Subscribes the passed function to the passed topic once
 * @param {string} topic The topic to subscribe to
 * @param {Function} callback The function to call when a new message is published
 */
function subscribeOnce(topic: string, callback: Function | PubSubJS.SubscriptionListener<any>): void {
    const subscribeTopic = topic.toLowerCase().replace(/\//g, '.');

    PubSub.subscribeOnce(subscribeTopic, callback as PubSubJS.SubscriptionListener<any>);
}

const logPrefix = 'AWW';

/**
 * Internal method for log style
 * @param {string} background Background color
 * @param {string} text Text color
 * @return {string} The console style
 */
function logStyle(background: string, text: string = '#fff'): string {
    if (!/^#/.test(background)) {
        background = `#${background}`;
    }

    if (!/^#/.test(text)) {
        text = `#${text}`;
    }

    // Convert to gradient
    background = `linear-gradient(180deg, ${lightenDarkenColor(background, 25)} 0%, ${lightenDarkenColor(background, -25)} 100%)`;

    return `background: ${background}; color: ${text}; font-weight: bold; font-size: 12px; display: inline-block; border-radius: 4px; padding: 4px 8px`;
}

/**
 * Lightens/Darkens color for a specific amount
 */
function lightenDarkenColor(color: string, amount: number) {
    let usePound = false;
    if (color[0] == '#') {
        color = color.slice(1);
        usePound = true;
    }

    let num = parseInt(color, 16);

    let r = (num >> 16) + amount;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    let b = ((num >> 8) & 0x00FF) + amount;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    let g = (num & 0x0000FF) + amount;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}

/**
 * Internal log method
 * @param {*=}    message  Message to show
 * @param {...*=} optional Additional messages to show
 */
function cLog(message: any, ...optional: any[]): void {
    if (import.meta.env.DEV) {
        // eslint-disable-next-line no-console
        console.log(`%c${logPrefix}`, logStyle('#606060'), message, ...optional);
    }
}

/**
 * Internal log method
 * @param {*=}    message  Message to show
 * @param {...*=} optional Additional messages to show
 */
function cInfo(message: any, ...optional: any[]): void {
    if (import.meta.env.DEV) {
        // eslint-disable-next-line no-console
        console.log(`%c${logPrefix}`, logStyle('#339cdc'), message, ...optional);
    }
}

/**
 * Internal log method
 * @param {*=}    message  Message to show
 * @param {...*=} optional Additional messages to show
 */
function cWarn(message: any, ...optional: any[]): void {
    if (import.meta.env.DEV) {
        // eslint-disable-next-line no-console
        console.log(`%c${logPrefix}`, logStyle('#e8de20', '#000'), message, ...optional);
    }
}

/**
 * Internal log method
 * @param {*=}    message  Message to show
 * @param {...*=} optional Additional messages to show
 */
function cError(message: any, ...optional: any[]): void {
    if (import.meta.env.DEV) {
        // eslint-disable-next-line no-console
        console.log(`%c${logPrefix}`, logStyle('#ed1a1a'), message, ...optional);
    }
}

let axiosInstance: AxiosInstance | null = null;
function getAxiosInstance(): AxiosInstance {
    if (!axiosInstance) {
        axiosInstance =  axios.create({
            headers: {'X-Requested-With': 'XMLHttpRequest'},
        });
    }

    return axiosInstance;
}

/**
 * Wrapper for making an ajax get request
 */
function ajaxGet<R = AxiosResponse>(url: string, config?: AxiosRequestConfig): Promise<R> {
    const axios = getAxiosInstance();

    return axios.get(url, config);
}

/**
 * Wrapper for making an ajax post request
 */
function ajaxPost<R = AxiosResponse>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
    const axios = getAxiosInstance();

    return axios.post(url, data, config);
}

/**
 * Returns a clean URL
 * @param {string | null} url The input URL or if empty the current URL
 * @returns {string} The clean URL
 */
function getCleanUrl(url: string | null = null): string {
    let cleanUrl = url || window.location.href;

    cleanUrl = cleanUrl.replace(window.location.origin, '');

    return cleanUrl;
}

/**
 * Returns the token char to use for a query string
 */
function getQueryStringToken(url: string): string {
    return url.match(/\?+/) ? '&' : '?';
}

/**
 * Shorthand method for pushing a new history state
 */
function historyPush(state: Partial<HistoryState>): void {
    publish('history.state.push', state);
}

/**
 * Shorthand method for replacing a history state
 */
function historyReplace(state: Partial<HistoryState>): void {
    publish('history.state.replace', state);
}

/**
 * Shorthand method for updating a history state
 */
function historyUpdate(state: Partial<HistoryState>): void {
    publish('history.state.update', state);
}

/**
 * Returns the Craft CSRF token to be used in POST forms
 */
async function getCsrfToken(): Promise<string> {
    const {data} = await ajaxGet('/actions/blitz/csrf/json');

    return data.token;
}

/**
 * Scrolls the element instantly to the given position
 */
function scrollToInstant(element?: Window|HTMLElement, options: ScrollToOptions = {}): void {
    if (!element) {
        return;
    }

    const scrollOptions = {...options, behavior: 'instant'};

    if (!('scrollTo' in element)) {
        return;
    }

    // @ts-ignore
    element.scrollTo(scrollOptions);
}

export {
    onReady,
    onLoad,
    onIdle,
    pxToRem,
    remToPx,
    publish,
    subscribe,
    subscribeOnce,
    cInfo,
    cLog,
    cWarn,
    cError,
    ajaxGet,
    ajaxPost,
    getCleanUrl,
    getQueryStringToken,
    historyPush,
    historyReplace,
    historyUpdate,
    getCsrfToken,
    scrollToInstant,
};
