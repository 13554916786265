// @ts-nocheck
import $ from 'cash-dom';

$.camelCase = (str: string) => {
    return str.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
};

$.isString = (x: any) => typeof x === 'string';

/**
 * Returns the scroll top value for the given element
 * @param {Number=} val (Optional) Scrolltop value to set
 * @return {undefined|Number} The scroll top value or undefined
 */
$.fn.scrollTop = function(val) {
    // Check if we have at least one element
    if (this.length === 0) {
        return undefined;
    }

    // Get first element of collection
    let element = this.get(0);

    let win;
    if (element.window) {
        win = element;
    } else if (element.nodeType === 9) { // Document element
        win = element.defaultView;
    }

    // Get proper var to use for indicating scrollTop value
    let prop = win ? 'scrollY' : 'scrollTop';

    // No val given -> return scrollTop value of element
    if (val === undefined) {
        return win ? win[prop] : element[prop];
    }

    if (win) {
        win.scrollTo(win.scrollY, val);
    } else {
        element[prop] = val;
    }
};

/**
 * Focuses something
 */
$.fn.focus = function() {
    // Check if we have at least one element
    if (this.length === 0) {
        return undefined;
    }

    // Get first element of collection
    let element = this.get(0);

    element?.focus();
};

/**
 * Overwrite current data behavior with old jquery/case 3.2 data behavior
 * @param {string=} name  (Optional) The data name to fetch/set
 * @param {mixed=}  value (Optional) The value to set for the given name
 * @return {mixed} The related data
 */
$.fn.data = function(name?: string, value?: any) {
    const dataNamespace = '__cashData';
    const dataAttributeRe = /^data-(.*)/;
    const getDataCache = (ele) => {
        return ele[dataNamespace] = (ele[dataNamespace] || {});
    };

    const getData = (ele, key) => {
        const cache = getDataCache(ele);

        if (key) {
            if (!(key in cache)) {
                let value = ele.dataset ? ele.dataset[key] || ele.dataset[$.camelCase(key)] : $(ele).attr(`data-${key}`);

                if (value !== undefined) {
                    try {
                        value = JSON.parse(value);
                    } catch (e) { }

                    cache[key] = value;
                }
            }

            return cache[key];
        }

        return cache;
    };

    const setData = (ele, key, value) => {
        getDataCache(ele)[key] = value;
    };

    if (!name) {
        // Get out if no element found
        if (!this[0]) {
            return;
        }

        // Loop over attributes
        $.each(this[0].attributes, (i, attr) => {
            const match = attr.name.match(dataAttributeRe);
            if (!match) {
                return;
            }

            this.data(match[1]);

        });

        return getData(this[0]);
    }

    if (typeof name === 'string') {
        if (value === undefined) {
            return this[0] && getData(this[0], name);
        }

        return this.each((idx, ele) => {
            setData(ele, name, value)
        });
    }

    for (let key in name) {
        this.data(key, name[key]);
    }

    return this;
};