import { onReady } from "../humans/helper";
import $, {Cash} from 'cash-dom';

export default class News {
    /**
     * Cash body reference
     */
    protected $body: Cash;

    /**
     * UI references
     */
    protected ui = {
        backButton: ".js-news-back",
    };

    /**
     * Constructor
     */
    public constructor() {
        this.$body = $('body');

        this.registerEvents();
    }

     /**
     * Register required events
     */
     protected registerEvents(): void {
        // Add scroll listener to window element
        this.$body.on('click', this.ui.backButton, this.newsBack.bind(this));
    }

    /**
     * Initialize Swipers
     */
    protected newsBack(event: Event) {
        // Check if the user came from the /news page
        if (document.referrer.indexOf(window.location.origin + "/news") !== -1) {
            event.preventDefault();
            history.back();
        }
    }
}
