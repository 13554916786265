import {
    ajaxGet,
    ajaxPost,
    cError,
    getCsrfToken,
    historyReplace,
    onIdle,
    publish,
    scrollToInstant,
    subscribe
} from '../humans/helper';
import $, {Cash} from 'cash-dom';
import qs from 'qs';
import {throttle} from 'throttle-debounce';
import {HistoryType, InternalHistoryState} from './types/history';
import {
    AsyncListingResponse,
    FetchPageParams,
    FilterCountResponse,
    ListingListSorting,
    ListingListType,
    ListingParams,
    ListingRenderMode
} from './types/listing';
import {Percentage} from './types/general';
import {AxiosResponse} from 'axios';
import History from './history';

/**
 * Listing Module
 * Handles all listing relevant actions
 * @package AWW
 * @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
 */
export default class Listing {
    /**
     * Cash body reference
     */
    protected $body: Cash;

    /**
     * Listing params for the URL to apply
     */
    protected listingParams: Partial<ListingParams> = {};

    /**
     * UI references
     */
    protected ui = {
        listing: '.teaser-list',
        infiniteListing: '.teaser-list[data-list-infinite-supported="true"]',
        listingWithListType: '.teaser-list[data-list-type]',
        displaySwitchButton: '.js-display-switch',

        // Filter
        filterForm: '.js-listing-filter-form',
        filterCheckbox: '.js-filter-checkbox',
        filterShowResultsBtn: '.js-show-results-btn',
        filterLocationListing: '.filter__list[aria-hidden=true]',

        // Sorting
        teaserListSortingContainer: '.js-list-sorting-container',
        teaserListSortingButton: '.js-list-sorting',

        // General
        csrfTokenField: 'input[name="CRAFT_CSRF_TOKEN"]',
    }

    /**
     * Cached promises object for new pages
     */
    protected pageLoadPromises: {[key: string]: Promise<AsyncListingResponse>} = {};

    /**
     * Indicates, if data is currently loaded
     */
    protected isLoadingData: boolean = false;

    /**
     * The next page render threshold in percentage, relative to the viewport height
     */
    protected nextPageRenderThreshold: Percentage = 10;

    /**
     * Listing constructor
     */
    constructor() {
        this.$body = $('body');

        this.updateListingParamsFromUrl();
        this.registerEvents();
        this.checkActiveFilter();
        this.loadFilterCounts();

        // Preload content once the browser is idle
        onIdle(this.preloadContent.bind(this));
    }

    /**
     * Register required DOM and PubSub events
     */
    protected registerEvents(): void {
        this.$body.on('click', this.ui.displaySwitchButton, this.onSwitchListingDisplayClick.bind(this));
        this.$body.on('change', this.ui.filterCheckbox, this.onFilterChange.bind(this));
        this.$body.on('click', this.ui.teaserListSortingButton, this.onFilterListSorting.bind(this));

        subscribe('history.state.pushed', this.onHistoryStateChanged.bind(this));
        subscribe('history.state.replaced', this.onHistoryStateChanged.bind(this));
        subscribe('history.state.popped', this.onHistoryStateChanged.bind(this));
    }

    /**
     * Handles modal relevant history changes
     */
    protected onHistoryStateChanged(topic: string, state: Partial<InternalHistoryState>): void {
        if (!History.isStateOfType(state, HistoryType.Listing, topic)) {
            return;
        }

        this.updateListingParamsFromUrl();
    }

    /**
     * Uses the listing params from the URL
     */
    protected updateListingParamsFromUrl(): void {
        this.listingParams = qs.parse(window.location.search.replace(/^\?+/, '')) as Partial<ListingParams>;
    }

    /**
     * Preloads the content for all listings on the page
     */
    protected preloadContent() {
        $(this.ui.infiniteListing).each((idx: number, element: HTMLElement) => {
            const $element = $(element);
            const section = $element.data('listSection');
            const path = $element.data('listDynamicPath');
            const page = Number(this.listingParams.page ?? $element.data('listInfinitePage') ?? 1) + 1;
            const listType = $element.data('listType') ?? 'grid';
            const sorting = this.listingParams.sorting ?? null;
            const filter = this.listingParams.filter ?? null;

            // Preload next page
            this.fetchPage({section, page, filter, path, listType, sorting} as FetchPageParams);

            // Check if page should be directly rendered
            this.shouldRenderNextPage($element);

            // Add scroll listener to check if next page should be rendered
            window.addEventListener('scroll', throttle(200, () => {
                this.shouldRenderNextPage($element);
            }), {passive: true});
        });
    }

    /**
     * Fetches a new page from the server for the given params
     */
    protected fetchPage(params: FetchPageParams): Promise<AsyncListingResponse> {
        const key = `${params.section}-${params.page}-${params.listType}-${JSON.stringify(params.filter)}-${params.sorting}`;

        if (key in this.pageLoadPromises) {
            return this.pageLoadPromises[key];
        }

        this.pageLoadPromises[key] = new Promise(async (resolve, reject) => {
            try {
                const {data} = await ajaxGet(`${params.path}?${qs.stringify({
                    section: params.section,
                    page: params.page,
                    listType: params.listType,
                    filter: params.filter,
                    sorting: params.sorting,
                })}`);

                resolve(data);
            } catch (error) {
                reject(error);
            }
        });

        return this.pageLoadPromises[key];
    }

    /**
     * Renders the next page for the given container
     */
    protected async renderNextPage($container: Cash): Promise<void>  {
        return this.renderPage($container, ListingRenderMode.Append);
    }

    /**
     * Renders a page for the given listing container
     */
    protected async renderPage($container: Cash, mode?: ListingRenderMode): Promise<void> {
        const $list = $container.find('.teaser-list__list');
        const path = $container.data('listDynamicPath');
        const section = $container.data('listSection');
        const filter = this.listingParams.filter ?? null;
        const listType = this.listingParams.listType ?? 'grid';
        const sorting = this.listingParams.sorting ?? null;
        let page = Number(this.listingParams.page ?? $container.data('listInfinitePage') ?? 1);

        if (mode === ListingRenderMode.Append) {
            page++;
        }

        try {
            this.isLoadingData = true;

            // Mark as busy just for first page
            if (page === 1) {
                $container.attr('aria-busy', 'true');
            }

            // Get data for next page
            const data = await this.fetchPage({section, page, filter, path, listType, sorting} as FetchPageParams);

            // Add markup to DOM
            requestAnimationFrame(() => {
                if (mode === ListingRenderMode.Append) {
                    $list.append(data.markup.join(''));
                } else {
                    $list.html(data.markup.join(''));
                }

                // Update list specific params
                $container.attr('data-list-infinite-page', String(page))
                    .attr('data-list-count', String($list.children().length))
                    .data('listInfinitePage', page)
                    .attr('data-list-infinite-supported', data.remaining > 0 ? 'true' : 'false');

                // Reset loading state
                this.isLoadingData = false;
                setTimeout(() => {
                    $container.attr('aria-busy', 'false');
                }, 10);

                // Publish update
                publish('listing.updated');

                if (data.remaining > 0) {
                    this.shouldRenderNextPage($container);

                    onIdle(() => {
                        this.fetchPage({section, page: page + 1, filter, path, listType, sorting} as FetchPageParams);
                    });
                }
            });

            // Update history state
            historyReplace({
                type: HistoryType.Listing,
                url: this.getHistoryUrl({page}),
            });
        } catch (error) {
            cError(error);
            $container.attr('aria-busy', 'false');
        } finally {
            // Make sure loading state is reset to initial state
            this.isLoadingData = false;
        }
    }

    /**
     * Checks whether the next page should be rendered for the given container
     */
    protected shouldRenderNextPage($container: Cash): void {
        if ($container.attr('data-list-infinite-supported') === 'false') {
            return;
        }

        const container = $container.get(0);
        if (typeof container === 'undefined') {
            return;
        }

        if (container.getBoundingClientRect().bottom < window.innerHeight + window.innerHeight * this.nextPageRenderThreshold / 100
            && !this.isLoadingData
        ) {
            this.renderNextPage($container);
        }
    }

    /**
     * Triggered when Grid/List button is clicked, we will switch the display based on the given listtype
     */
    protected async onSwitchListingDisplayClick(event: PointerEvent): Promise<void> {
        event.preventDefault();

        const $target = $(event.currentTarget as HTMLElement);
        const currentListType = $target.data('listType');
        const $container = $(this.ui.listingWithListType);

        // Toggle button
        const listType = currentListType !== 'grid' ? ListingListType.Grid : ListingListType.List;
        $target.data('listType', listType)
            .attr('data-list-type', listType);

        this.scrollToTop();

        // Special case: Projects have different order for grid <> list view -> Re-fetch content based on view
        if ($container.data('listSection') === 'projects') {
            this.listingParams = {...this.listingParams, page: 1, listType};

            // Reset sorting for grid view
            if (listType === ListingListType.Grid) {
                this.listingParams.sorting = null;

                // Hide sorting panel directly
                $(this.ui.teaserListSortingContainer).attr('aria-hidden', 'true');
            } else if (listType === ListingListType.List) {
                // Default sorting: New to old
                this.listingParams.sorting = ListingListSorting.Desc;
            }

            await this.renderPage($container);
        }

        // Switch display and sorting if required
        this.switchListingDisplay(listType);
        this.switchSortingActiveState();

        // Replace history state
        // @ts-ignore
        historyReplace({
            type: HistoryType.Listing,
            url: this.getHistoryUrl({listType}),
        });
    }

    /**
     * Switches the list view from grid <> list
     */
    protected switchListingDisplay(listType: ListingListType): void {
        $(this.ui.listingWithListType).each((idx: number, element: HTMLElement) => {
            const $element = $(element);

            // Switch list style
            $element.attr('data-list-type', listType);

            // Set active class
            $(this.ui.displaySwitchButton).filter(`[data-list-type="${listType}"]`);

            // Show hide sorting panel
            $(this.ui.teaserListSortingContainer).attr('aria-hidden', listType === ListingListType.List ? 'false' : 'true');

            // Check if next page should be rendered
            this.shouldRenderNextPage($element);
        });
    }

    /**
     * Triggered, when a filter item is checked/unchecked
     * We will render the filtered page and update the filter counts based on the current selected filter
     */
    protected onFilterChange(event: Event): void {
        const $target = $(event.currentTarget as HTMLElement);
        const $filterForm = $target.parents('form');

        // Render selected filter
        requestAnimationFrame(() => {
            this.renderSelectedFilter();
        });

        // Scroll to top
        this.scrollToTop();

        const filter = qs.parse($filterForm.serialize())?.filter ?? null;

        // Update history path
        historyReplace({
            type: HistoryType.Listing,
            url: this.getHistoryUrl({filter, page: 1} as Partial<ListingParams>),
        });

        $filterForm.attr('aria-busy', 'true');

        // Show results button already
        $(this.ui.filterShowResultsBtn).attr('aria-hidden', 'false');

        // Wait till all data has been loaded
        Promise.all([
            this.renderPage($('.teaser-list'), ListingRenderMode.Replace),
            this.loadFilterCounts(),
        ]).then(() => {
            $filterForm.attr('aria-busy', 'false');
        });
    }

    /**
     * Checks the active filter, that are currently set via the URL
     */
    protected checkActiveFilter(): void {
        if (!this.listingParams.filter) {
            return;
        }

        const $filterForm = $(this.ui.filterForm);
        if ($filterForm.length === 0) {
            return;
        }

        // Set checkbox to active for current filters
        Object.entries(this.listingParams.filter).forEach(([filterGroup, selectedFilter]) => {
            selectedFilter.forEach((filterId: number | string) => {
                $filterForm.find(`input[type="checkbox"][data-related-field="${filterGroup}"][value="${filterId}"]`)
                    .prop('checked', true);
            });
        });

        // Render selection in frontend
        this.renderSelectedFilter();
    }

    /**
     * Renders the selected filter on top of the listing
     */
    protected renderSelectedFilter(): void {
        const $filterForm = $(this.ui.filterForm);
        if ($filterForm.length === 0) {
            return;
        }

        const items: string[] = [];

        $filterForm.find('input:checked').each((idx: number, element: HTMLElement) => {
            const $element = $(element);
            const $title = $element.parents('.filter__list, .filter__group').find('.filter__list-heading');

            const $listClone = $element.parent().clone();
            $listClone.find('input').remove();
            $listClone.find('.filter__item-count').remove();
            $listClone.find('label').addClass('btn btn--tag').removeClass('checkbox__label');

            if ($title.length > 0) {
                $listClone.find('label').html(`<span>${$title.text().trim()}</span> ${$listClone.find('label').text()}`);
            }

            items.push(`<li class="selected-filter__item">${$listClone.html()}</li>`);
        });

        $('.selected-filter').html(items.join('')).toggleClass('has-active-filters', items.length > 0);
    }

    /**
     * Loads the filter counts if current page contains a filter form
     */
    protected async loadFilterCounts(): Promise<void> {
        const $filterForm = $(this.ui.filterForm);
        if ($filterForm.length === 0) {
            return;
        }

        const $csrfTokenField = $filterForm.find(this.ui.csrfTokenField);

        // Token field already has a value
        if ($csrfTokenField.val().length > 0) {
            await this.processLoadFilterCounts($csrfTokenField.val() as string);
            return;
        }

        // Request CSRF token and continue
        // Fallback for token will not work without blitz enabled - but not needed anyways
        // const token = await getCsrfToken();
        // $csrfTokenField.val(token);
        // await this.processLoadFilterCounts(token);
    }

    /**
     * Performs the request to load the filter counts
     */
    protected async processLoadFilterCounts(csrfToken: string): Promise<void> {
        const $filterForm = $(this.ui.filterForm);

        // Collect all options in filter form
        const filterOptions: {[key: string]: string[]} = {};
        $(this.ui.filterCheckbox).each((idx: number, element: HTMLElement) => {
            const $element = $(element);
            const field = $element.data('relatedField');
            const value = $element.attr('value') ?? '';

            if (value.length > 0) {
                if (!(field in filterOptions)) {
                    filterOptions[field] = [];
                }

                filterOptions[field].push(value);
            }
        });

        // Perform request for filter counts
        try {
            const {data} = await ajaxPost<AxiosResponse<FilterCountResponse>>($filterForm.data('filterCountPath'), {
                filterOptions,
                section: $filterForm.data('section'),
                currentFilter: this.listingParams.filter,
                CRAFT_CSRF_TOKEN: csrfToken,
            });

            if (data.success && data.result) {
                Object.entries(data.result).forEach(([filterId, count]) => {
                    const value = Number(count ?? 0);
                    const $label = $(`label[for="filter-${filterId}"]`);

                    // We don’t show the available icons - leaving this here for testing
                    $label.find('.filter__item-count').html(String(value));

                    $label.prev().filter(':not(:checked)').prop('disabled', value === 0);
                });
            }
        } catch (error) {
            cError(error);
        }
    }

    /**
     * Triggered, when the list sorting should be changed
     */
    protected async onFilterListSorting(event: PointerEvent): Promise<void> {
        event.preventDefault();

        const $container = $(this.ui.listingWithListType);

        if ($container.data('listSection') !== 'projects') {
            return;
        }

        // Update sorting direction
        const $target = $(event.currentTarget as HTMLElement);
        this.listingParams.sorting = $target.data('sorting');

        this.switchSortingActiveState();

        // Update listing params and render page
        this.listingParams = {...this.listingParams, page: 1};
        await this.renderPage($container);
    }

    /**
     * Switches the active state of the sorting buttons
     */
    protected switchSortingActiveState(): void {
        // toggle sort direction
        const newSorting = this.listingParams.sorting === ListingListSorting.Desc ? ListingListSorting.Asc : ListingListSorting.Desc;
        $(this.ui.teaserListSortingButton).data('sorting', newSorting).attr('data-sorting', newSorting);
    }

    /**
     * Returns the proper history URL to set for the given update params
     */
    protected getHistoryUrl(params?: Partial<ListingParams>): string {
        return `${window.location.pathname}?${this.getListingParams(params)}`;
    }

    /**
     * Returns the stringified version for the params object to apply for history URL changes
     */
    protected getListingParams(params?: Partial<ListingParams>): string {
        if (params) {
            this.listingParams = {...this.listingParams, ...params};
        }

        const filteredParams = Object.fromEntries(Object.entries(this.listingParams).filter(([_, val]) => val !== null));

        return qs.stringify(filteredParams);
    }

    /**
     * Scrolls the current window view to top if required
     */
    protected scrollToTop(): void {
        const mainPosition = $('.main').get(0)?.getBoundingClientRect();
        if (!mainPosition) {
            return;
        }

        if (mainPosition.top < 0) {
            scrollToInstant(window, {top: window.scrollY + mainPosition.top});
        }
    }
}


