/**
 * Represents a history state to be used on the site
 */
export interface HistoryState {
    id: string,
    url: string,
    mainUrl: string,
    title: string,
    index: number,
    type: HistoryType,
    scrollPosition: number,
    data: unknown,
    hash: string,
    modalLevel: number,
    modalUrl: string[],
    modalSize: string[],
    modalHistoryCount?: number[],
}

/**
 * Represents an extended history state, that is not directly visible within the browser
 * but visible inside PubSub events
 */
export interface InternalHistoryState extends HistoryState {
    previousState?: Partial<HistoryState>,
    direction: HistoryDirection,
}

/**
 * Represents the history navigation direction
 */
export enum HistoryDirection {
    Back = 'back',
    Forward = 'forward',
}

/**
 * Possible History types
 */
export enum HistoryType {
    Initial = 'initial',
    Listing = 'listing',
    ModalInline = 'modal-inline',
    ModalAsync = 'modal-async',
}