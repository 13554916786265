// @ts-ignore
// core version + navigation, pagination modules:
import Swiper from 'swiper';
import { Autoplay, Keyboard, Pagination, Navigation } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { onReady } from '../humans/helper';
import $ from 'cash-dom';

export default class Carousel {
    protected swipers: Swiper[] = [];
    /**
     * UI references
     */
    protected ui = {
        carousels: '.js-carousel',
    };

    /**
     * Constructor
     */
    constructor() {
        this.registerEvents();
    }

    /**
     * Register required events
     */
    protected registerEvents(): void {
        onReady(this.initSwipers.bind(this));
    }

    /**
     * Initialize Swipers
     */
    protected initSwipers(): void {
        $(this.ui.carousels).each((index: number, element: HTMLElement) => {

            // Define swiper type based on [data-swiper-type]
            const swiperType = $(element).data('swiper-type') || 'default';

            // Define autoplay timing based on [data-swiper-delay]
            const swiperDelay = $(element).data('swiper-delay') || '0';

            // Get swiper options
            const swiperParams = this.getSwiperParams(swiperType, swiperDelay);

            // Init swiper
            const swiper = new Swiper(element, swiperParams);
            this.swipers.push(swiper);

            // swiper.on('slideChange', function () {
            //     console.log('slide changed');
            // });
        });
    }

    /**
     * Get Swiper parameters based on swiper type
     * @param swiperType - Value of data-swiper-type attribute
     * @param swiperDelay - Value of data-swiper-delay attribute
     * @returns SwiperOptions
     */
    protected getSwiperParams(swiperType: string | undefined, swiperDelay: number): SwiperOptions {
        // Default parameters
        let params: SwiperOptions = {
            // Docs: https://swiperjs.com/swiper-api

            // Install modules
            modules: [Autoplay, Keyboard, Pagination, Navigation],

            // loop: true,
            lazyPreloadPrevNext: 2,
            // longSwipes: false,
            // longSwipesMs: 200,
            // cssMode: true,

            // Duration of transition between slides (in ms)
            // Default: 300
            // speed: 150,

            // Threshold value in px. If "touch distance" will be lower than this value then swiper will not move
            // Default:5
            // threshold: 5,

            // spaceBetween: 50,
            // resistance: false,
            // effect: 'fade',
            // fadeEffect: {
            //     crossFade: true
            // },
            // keyboard: {
            //     enabled: true,
            //     onlyInViewport: false,
            // },
            pagination: {
                el: '.swiper-pagination',
                // type: "fraction",
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            // autoplay: {
            //     delay: 5000,
            // },
        };

        // Customize parameters based on swiperType home-slider
        if (swiperType === 'home-slider') {
            // Duration of transition between slides
            params.speed = 600;

            // Set to true to enable continuous loop mode
            params.loop = true;

            // Object with autoplay parameters or boolean true to enable with default settings
            params.autoplay = {
                delay: swiperDelay,
            };

            params.pagination = {
                el: '.swiper-pagination',
                type: 'fraction',
            };
        }

        // Customize parameters based on swiperType gallery
        if (swiperType === 'gallery') {
            // Set to true to enable continuous loop mode
            params.loop = true;

            params.spaceBetween = 20;
            params.pagination = {
                el: '.swiper-pagination',
                clickable: true,
            };

            params.keyboard = {
                enabled: true,
            };
        }

        return params;
    }
}
