import '../styles/main.scss';
import 'vite/modulepreload-polyfill';
import $ from 'cash-dom';
import './humans/cash-dom/mixins';
import Listing from './aww/listing';
import History from './aww/history';
// import ScrollListener from './aww/scroll-listener';
import Carousel from './aww/carousel';
import Search from './aww/search';
import ImgFade from './aww/img-fade';
import Modal from './aww/modal';
import Video from './aww/video';
import News from './aww/news';
import Contact from './aww/contact';

// HUM credit
// @todo: Add credit here @raffael / @tim
// console.log("%cDesigned %cby\nDavid Chipperfield Architects\nwith Humans & Machines\n\nBuilt by\nHumans & Machines\n%chttps://humans-machines.com", "padding:1.2rem 0 0;display:block;font-family:sans-serif;font-size:0.8rem;line-height:1.1;", "padding:0;font-family:sans-serif;font-size:0.8rem;line-height:1.1;", "padding:0 0 1.2rem;font-family:sans-serif;font-size:0.8rem;line-height:1.1;");

// Create module instances
new History();
new Listing();
// new ScrollListener();
new Carousel();
new Search();
new ImgFade();
new Modal();
new Video();
new News();
new Contact();


// Add gsap scroll trigger if requested
if ($('.js-gsap').length) {
    import('./aww/scroll-animation.js').then((module) => {
        new module.default();
    });
}

// Accept hot module reloading
if (import.meta.hot) {
    // Dev related
    import('./humans/dev/vite.js').then((module) => {
        module.default();
    });

    // Enable HMR
    import.meta.hot.accept();

    // Show a message, when a spefic file was reloaded
    // @ts-ignore
    import.meta.hot.on('vite:afterUpdate', (update: UpdatePayload) => {
        // @ts-ignore
        update.updates.forEach((upd: Update) => {
            let info = {msg: 'HMR Update', color: '#000', background: '#17ef79'};
            if (/\.scss$/i.test(upd.path)) {
                info = {msg: 'SCSS Update', color: '#fff', background: '#CE679B'};
            } else if (/\.ts$/i.test(upd.path)) {
                info = {msg: 'TS Update', color: '#fff', background: '#2D79C7'};
            } else if (/\.js$/i.test(upd.path)) {
                info = {msg: 'JS Update', color: '#000', background: '#F0D81D'};
            } else if (/\.vue$/i.test(upd.path)) {
                info = {msg: 'VUE Update', color: '#32475B', background: '#3EB27F'};
            }

            // eslint-disable-next-line no-console, max-len
            console.log(`%c🎉 ${info.msg}!`, `color: ${info.color}; background: ${info.background}; font-weight: bold; display: inline-block; border-radius: 4px; padding: 3px 6px;`);
        });
    })

    // Remove previously added event listeners for body and window
    import.meta.hot.dispose((data) => {
        $('body').off();
        $(window).off();
    });
}