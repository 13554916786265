import $ from 'cash-dom';
import {cLog, getCleanUrl, onLoad, publish, subscribe} from '../humans/helper';
import {HistoryDirection, HistoryState, HistoryType, InternalHistoryState} from './types/history';

/**
 * History component
 * @package AWW
 * @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
 */
export default class History {
    /**
     * Current history index
     */
    protected currentIndex: number = 0;

    /**
     * Current document title
     */
    protected documentTitle: string | null = null;

    /**
     * Holds the current history, which will be passed as previousState in PubSub events
     */
    protected currentState?: Partial<HistoryState>;

    /**
     * History constructor
     */
    public constructor() {
        this.registerEvents();
    }

    /**
     * Register required browser and PubSub events
     */
    protected registerEvents(): void {
        $(window).on('popstate', this.onPopState.bind(this));

        // PubSub Events
        subscribe('history.state.push', this.onPushState.bind(this));
        subscribe('history.state.replace', this.onReplaceState.bind(this));
        subscribe('history.state.update', this.onUpdateState.bind(this));

        this.documentTitle = document.title;
        this.setInitialState();
    }

    /**
     * Set initial history state once the site is loaded
     */
    protected setInitialState(): void {
        if (history.state) {
            this.currentIndex = history.state.index;
            this.currentState = {...history.state} as Partial<HistoryState>;
        } else {
            this.currentIndex = 0;

            // Setup initial state
            this.replace({
                url: getCleanUrl(),
                type: HistoryType.Initial,
                // @ts-ignore
                scrollPosition: $(window).scrollTop(),
            } as Partial<HistoryState>);
        }
    }

    /**
     * Triggered, when a new state should be pushed
     */
    protected onPushState(topic: string, data: Partial<HistoryState>): void {
        this.push(data);
    }

    /**
     * Triggered, when a state should be replaced
     */
    protected onReplaceState(topic: string, data: Partial<HistoryState>): void {
        this.replace(data);
    }

    /**
     * Triggered, when the internal state should be updated
     */
    protected onUpdateState(topic: string, data: Partial<HistoryState>): void {
        this.update(data);
    }

    /**
     * Triggered on popstate
     */
    protected onPopState(event: PopStateEvent): void {
        if (event.state) {
            const currentIndex = event.state.index;
            const lastIndex = this.currentIndex;
            const direction = currentIndex < lastIndex ? HistoryDirection.Back : HistoryDirection.Forward;

            // Update current index
            this.currentIndex = currentIndex;

            // Update title
            document.title = event.state.title || this.documentTitle;

            // Set previous state
            const previousState = this.currentState;

            // Publish popstate event
            publish('history.state.popped', {direction, ...event.state, previousState} as Partial<InternalHistoryState>);

            // Update current state
            this.currentState = {...event.state};
        }
    }

    /**
     * Pushes a new history state for the given params
     */
    protected push(historyState: Partial<HistoryState> = {}): void {
        let {url, title, ...data} = historyState;

        if ('pushState' in window.history) {
            url = getCleanUrl(url);
            title = title ?? document.title;

            // Define unique ID for state
            if (!('id' in data)) {
                data.id = this.getStateId();
            }

            // Attach hash to URL
            if ('hash' in data) {
                url = `${url}#${data.hash}`;
            }

            // Define state
            const state: Partial<HistoryState> = {
                url,
                title,
                ...data,
            };

            // Get index from stack
            const index = this.currentIndex + 1;
            this.currentIndex = index;

            const previousState = this.currentState;
            const newState = {...state, index} as Partial<HistoryState>;

            // Push history state and update title
            history.pushState(newState, title, url);
            document.title = title;

            // Publish push state
            publish('history.state.pushed', {...newState, previousState} as Partial<InternalHistoryState>);

            // Update current state
            this.currentState = {...newState};
        }
    }

    /**
     * Replaces the current history state for the given params
     */
    protected replace(historyState: Partial<HistoryState> = {}): void {
        let {url, title, ...data} = historyState;

        if ('replaceState' in window.history) {
            url = getCleanUrl(url);
            title = title || document.title;

            // Define unique ID for state
            if (!('id' in data)) {
                data.id = this.getStateId();
            }

            // Define state
            const state: Partial<HistoryState> = {
                url,
                title,
                ...data,
            };

            // Get index from stack
            const index = this.currentIndex;
            const previousState = this.currentState;
            const newState = {...state, index} as Partial<HistoryState>;

            // Replace history state and update title
            history.replaceState(newState, title, url);
            document.title = title;

            // Publish push state
            publish('history.state.replaced', {...newState, previousState} as Partial<InternalHistoryState>);

            // Update current state
            this.currentState = {...newState} as Partial<HistoryState>;
        }
    }

    /**
     * Updates the state in the internal state stack
     */
    protected update(historyState: Partial<HistoryState> = {}): void {
        let {index, ...data} = historyState;

        const stackIndex = index || this.currentIndex;

        if (stackIndex === this.currentIndex) {
            const originalState: Partial<HistoryState> = {...history.state};
            const updatedState: Partial<HistoryState> = {...originalState, ...data};

            this.replace(updatedState);
            publish('history.state.updated', {index: stackIndex, ...updatedState} as Partial<InternalHistoryState>);
        }
    }

    /**
     * Returns a unique ID for the current state
     * @return {string} The unique ID
     */
    protected getStateId(): string {
        return Math.random().toString(36).substr(2, 10);
    }

    /**
     * Indicates if the given history state includes the given type
     */
    public static isStateOfType(state: Partial<InternalHistoryState>, type: HistoryType, changeType?: string): boolean {
        if (!state.type && !state.previousState?.type) {
            return false;
        }

        const checkType: HistoryType[] = [type];

        if (changeType) {
            if (['history.state.pushed', 'history.state.replaced'].includes(changeType)
                || (changeType === 'history.state.popped' && state.direction === HistoryDirection.Forward)
            ) {
                return checkType.includes(state.type as HistoryType);
            } else if (changeType === 'history.state.popped') {
                return checkType.includes(state.previousState?.type as HistoryType);
            }
        }

        return checkType.includes(state.type as HistoryType)
            || checkType.includes(state.previousState?.type as HistoryType);
    }
}