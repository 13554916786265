import $, {Cash} from 'cash-dom';
import { cLog, historyReplace, getCleanUrl } from '../humans/helper';

/**
 * Search Module
 * Handles filtering and sorting for the search module
 * @package AWW
 * @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
 */
export default class Search {
    /**
     * Cash body reference
     */
    protected $body: Cash;

    /**
     * UI references
     */
    protected ui = {
        searchField: '.js-search-field',
        searchFilterCheckbox: '.js-search-filter',
        searchResultEntry: '.search-result__entry',
        searchSortingSelect: '.js-search-sorting',
        searchSortInput: '.js-sort-input'
    }

    protected typeWithDate = ['project', 'news', 'writing', 'pressCutting', 'publications', 'exhibitions', 'videoAndAudio'];

    /**
     * Search constructor
     */
    constructor() {
        this.$body = $('body');
        this.registerEvents();
    }

    /**
     * Register required DOM and PubSub events
     */
    protected registerEvents(): void {
        this.$body.on('focus', this.ui.searchField, this.onSearchFieldFocus.bind(this));
        this.$body.on('change', this.ui.searchFilterCheckbox, this.onSearchFilterChange.bind(this));
        this.$body.on('click', this.ui.searchSortingSelect, this.onSearchSortingChange.bind(this));

        this.setInitialSorting();
    }

    /**
     * Set initial sorting of search results once the site is loaded
     */
    protected setInitialSorting(): void {
        // Get sorting url param
        const urlParams = new URLSearchParams(window.location.search);
        const sorting = urlParams.get('s');

        sorting ? this.sortSearchResults(sorting as string) : null;
    }

    /**
     * Triggered when the search field is focussed. We will select the text inside the field
     */
    protected onSearchFieldFocus(event: FocusEvent): void {
        const $target = $(event.currentTarget as HTMLInputElement);
        if ($target.val().length > 0) {
            // @ts-ignore
            $target.get(0)?.select();
        }
    }

    /**
     * Triggered, when a filter has changed. We will filter the list with the active filter
     * @protected
     */
    protected onSearchFilterChange(event: FocusEvent): void {
        // Get activated filter checkbox
        const $activeFilter = $(event.currentTarget as HTMLSelectElement);
        const $searchResultEntries = $(this.ui.searchResultEntry);

        // Uncheck all other filter checkboxes
        $(this.ui.searchFilterCheckbox).each((idx: number, element: HTMLElement) => {
            $activeFilter.attr('value') != $(element).attr('value') ? $(element).prop( "checked", false ) : null;
        });

        // Show all results
        $searchResultEntries.attr('aria-hidden', 'false').prop('hidden', false);

        // @ts-ignore
        const activeFilterValues = $activeFilter.attr('value');

        // Hide all filtered out entries
        if(activeFilterValues == 'all') {
            return;
        } else {
            $searchResultEntries.filter((_, element) => {
                // @ts-ignore
                return !activeFilterValues.includes($(element).data('searchType'));
            }).attr('aria-hidden', 'true').prop('hidden', true);
        }
    }

    /**
     * Triggered, when the sort dropdown value has changed. We will sort the list based on the selected value
     */
    protected onSearchSortingChange(event: Event): void {
        const $target = $(event.currentTarget as HTMLSelectElement);

        $(this.ui.searchSortingSelect).each((idx: number, element: HTMLElement) => {
            $(element).removeClass('is-active');
        });

        $target.addClass('is-active');

        const val = $target.text().toLowerCase();

        // keep track of sorting in hidden input
        $(this.ui.searchSortInput).val(val);

        // Set sorting url param
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('s', val);
        const newUrl = window.location.href.split('?')[0] + '?' + urlParams.toString();

        historyReplace({
            // @ts-ignore
            type: 'search',
            url: newUrl,
        });

        this.sortSearchResults(val as string);
    }

    /**
     * Sorts the entries based on the given sort type
     */
    protected sortSearchResults(sort: string): void {
        const $list = $('.teaser-list__list');

        interface SearchResultSortEntry {
            $element: Cash,
            type: string,
            position: number,
            date: number,
        }

        // @ts-ignore
        let entries = $(this.ui.searchResultEntry).map((_, element) => {
            const $element = $(element);

            return {
                $element,
                type: $element.data('searchType'),
                position: Number($element.data('searchPosition')),
                date: Number($element.data('searchDate'))
            } as SearchResultSortEntry
        }).get() as SearchResultSortEntry[];

        let sortCallback = null;

        const typeSortCallback = (a: SearchResultSortEntry, b: SearchResultSortEntry) => {
            return a.type.localeCompare(b.type);
        };

        const dateSortCallback = (a: SearchResultSortEntry, b: SearchResultSortEntry) => {
            if (a.date === b.date) {
                return 0;
            }

            return a.date > b.date ? -1 : 1;
        };

        // Prepare sort callback based on selected type
        switch (sort) {
            case 'relevance':
                sortCallback = (a: SearchResultSortEntry, b: SearchResultSortEntry) => {
                    if (a.position === b.position) {
                        return 0;
                    }

                    return a.position < b.position ? -1 : 1;
                };
                break;

            case 'type':
                sortCallback = typeSortCallback;
                break;

            case 'date':
                sortCallback = dateSortCallback;
                break;
        }

        if (typeof sortCallback === 'function') {
            if (sort !== 'date') {
                entries.sort(sortCallback);
            } else {
                const dateEntries = entries.filter(entry => this.typeWithDate.includes(entry.type));
                const typeEntries = entries.filter(entry => !this.typeWithDate.includes(entry.type));
                dateEntries.sort(dateSortCallback);
                typeEntries.sort(typeSortCallback);

                entries = dateEntries.concat(typeEntries);
            }
        }

        // Callback to show date and hide type in list
        function showDate (element: Cash) {
            element.find('.teaser__info-date').prop('hidden', false);
            element.find('.teaser__info-default').prop('hidden', true);
        }

        // Callback to show type and hide date in list
        function showType (element: Cash) {
            element.find('.teaser__info-date').prop('hidden', true);
            element.find('.teaser__info-default').prop('hidden', false);
        }

        // Re order entries
        entries.forEach((entry) => {
            if (sort === 'date' && this.typeWithDate.includes(entry.type)) {
                showDate(entry.$element);
            } else {
                showType(entry.$element);
            }

            $list.append(entry.$element);
        });
    }
}
