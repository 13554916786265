import $, {Cash} from 'cash-dom';
import {cLog, cWarn} from '@js/humans/helper';
import {ConvertedVideo} from '@js/aww/types/video';
import {debounce} from 'throttle-debounce';

/**
 * Autoplay video component
 * @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
 * @author Raffael Stpken <Raffael@Humans-Machines.com>
 *
 *
 * Add a video placeholder to be turned into a HTML video player
 * We do this to overcome autoplay issues mostly in Safari
 *
 * Script originally from Dinamo
 * We removed the player controls functionionality and IE support
 *
 * Use this template:
 *
 * <div class='js-video-loader'
 *      data-autoplay='1'
 *      data-muted='1'
 *      data-src='[{"media":"screen and (min-width: 1920px)","src":"https:\/\/cdn.abcdinamo.com\/media\/_1920xauto-q60\/Dinamo_Brains_34sec_Loop_03-web_3_2021-10-27-095526.mp4"},{"media":"screen and (min-width: 1280px)","src":"https:\/\/cdn.abcdinamo.com\/media\/_1440xauto-q60\/Dinamo_Brains_34sec_Loop_03-web_3_2021-10-27-095526.mp4"},{"media":"screen and (min-width: 1000px)","src":"https:\/\/cdn.abcdinamo.com\/media\/_960xauto-q60\/Dinamo_Brains_34sec_Loop_03-web_3_2021-10-27-095526.mp4"},{"media":"screen and (min-width: 600px)","src":"https:\/\/cdn.abcdinamo.com\/media\/_960xauto-q60\/Dinamo_Brains_34sec_Loop_03-web_3_2021-10-27-095526.mp4"},{"media":"screen and (max-width: 599px)","src":"https:\/\/cdn.abcdinamo.com\/media\/_640xauto-q60\/Dinamo_Brains_34sec_Loop_03-web_3_2021-10-27-095526.mp4"}]'
 *      data-poster='[{"width":1024,"src":"https:\/\/cdn.abcdinamo.com\/media\/_1024xauto-q90\/ABCDinamo-TeamMember-2.jpg"},{"width":800,"src":"https:\/\/cdn.abcdinamo.com\/media\/_800xauto-q90\/ABCDinamo-TeamMember-2.jpg"},{"width":640,"src":"https:\/\/cdn.abcdinamo.com\/media\/_640xauto-q90\/ABCDinamo-TeamMember-2.jpg"},{"width":480,"src":"https:\/\/cdn.abcdinamo.com\/media\/_480xauto-q90\/ABCDinamo-TeamMember-2.jpg"},{"width":320,"src":"https:\/\/cdn.abcdinamo.com\/media\/_320xauto-q90\/ABCDinamo-TeamMember-2.jpg"}]'
 *      >Video is loading ...</div>
 *
 */
export default class Video {
    /**
     * Define UI elements
     */
    protected ui = {
        videoContainer: '.video', // Containing wrapper
        videoPlayer: '.video__player', // HTML Video element
        autoplayVideo: '.video__player[data-autoplay="true"]',
        videoLoader: '.js-video-loader',
        videoPlayButton: '.js-video-play',
    };

    /**
     * Video state classes on container
     * @type {object}
     */
    protected stateClasses = {
        PLAYING: 'is-playing',
        MUTED: 'is-muted',
        SEEKING: 'is-seeking',
        PLAYED_BEFORE: 'has-played-before',
        VISIBLE_CONTROLS: 'has-visible-controls',
    };

    /**
     * Reference of the convert elements intersection observer
     */
    protected convertIntersectionObserver: IntersectionObserver | null = null;

    /**
     * Reference of the autoplay intersection observer
     */
    protected autoplayIntersectionObserver: IntersectionObserver | null = null;

    /**
     * Reference of the mutation observer
     */
    protected mutationObserver: MutationObserver | null = null;

    /**
     * VIDEO constructor
     */
    constructor() {
        $(this.onReady.bind(this));
    }

    protected onReady() {
        // Init intersection observers
        this.initConvertObserver();
        this.initAutoplayObserver();

        // observe related elements
        this.observeConvertElements();
        this.observeAutoplayElements();

        // Init mutation observer
        this.initMutationObserver();
    }

    /**
     * Indicates, if browser supports intersection observer
     * @return {boolean} True, if the browser support the intersection observer
     */
    protected useIntersectionObserver(): boolean {
        return 'IntersectionObserver' in window;
    }

    /**
     * Initializes the intersection observer for converting video elements
     */
    protected initConvertObserver(): void {
        if (!this.useIntersectionObserver()
            || this.convertIntersectionObserver !== null
            || $(this.ui.videoLoader).length === 0
        ) {
            return;
        }

        // Create an observer
        this.convertIntersectionObserver = new IntersectionObserver(this.handleConvertIntersection.bind(this), {
            root: null, // Use the viewport as the root
            rootMargin: '100% 100% 100% 100%', // Extend margin to trigger function more early
            threshold: [0, 1], // Trigger when a single pixel of the element is in the viewport
        });
    }

    /**
     * Initializes the intersection observer for converting video elements
     */
    protected initAutoplayObserver(): void {
        if (!this.useIntersectionObserver()
            || this.autoplayIntersectionObserver !== null
            || $(this.ui.videoLoader).length === 0
        ) {
            return;
        }

        // Create an observer
        this.autoplayIntersectionObserver = new IntersectionObserver(this.handleAutoplayIntersection.bind(this), {
            root: null, // Use the viewport as the root
            rootMargin: '0px -1px 0px -1px', // Extend margin to trigger function more early
            threshold: [0, 1], // Trigger when a single pixel of the element is in the viewport
        });
    }

    /**
     * Inits the mutation observer in order to check changes for autoplay videos/convert videos
     */
    protected initMutationObserver(): void {
        if (this.mutationObserver !== null) {
            return;
        }

        this.mutationObserver = new MutationObserver(debounce(500, (mudationList: MutationRecord[]) => {
            if (mudationList.length) {
                this.observeConvertElements();
                this.observeAutoplayElements();
            }
        }, {atBegin: false}));

        this.mutationObserver.observe(document.body, {childList: true, subtree: true});
    }

    /**
     * Observed the related elements
     */
    protected observeConvertElements(): void {
        if (!this.convertIntersectionObserver) {
            return;
        }

        // Observe video loaders
        $(this.ui.videoLoader).each((_: Number, element: HTMLElement) => {
            const $element = $(element);
            if (!$element.data('isObserved')) {
                this.convertIntersectionObserver?.observe(element);
                $element.data('observed', true);
            }
        });

        // Update intersections
        this.convertIntersectionObserver.takeRecords().forEach(this.handleConvertIntersectionEntry.bind(this));
    }

    /**
     * Observed the related elements
     */
    protected observeAutoplayElements(): void {
        if (!this.autoplayIntersectionObserver) {
            return;
        }

        // Observe video loaders
        $(this.ui.autoplayVideo).each((_: Number, element: HTMLElement) => {
            const $element = $(element);
            if (!$element.data('isObserved') && !$element.data('observationDisabled')) {
                this.convertIntersectionObserver?.observe(element);
                $element.data('observed', true);
            }
        });

        // Update intersections
        this.autoplayIntersectionObserver.takeRecords().forEach(this.handleAutoplayIntersectionEntry.bind(this));
    }

    /**
     * Triggered, once a convert video holder intersects with viewport
     */
    protected handleConvertIntersection(entries: IntersectionObserverEntry[]): void {
        entries.forEach(this.handleConvertIntersectionEntry.bind(this));
    }

    /**
     * Triggered, once a convert video holder intersects with viewport
     */
    protected handleAutoplayIntersection(entries: IntersectionObserverEntry[]): void {
        entries.forEach(this.handleAutoplayIntersectionEntry.bind(this));
    }

    /**
     * Handles the intersection for a single intersection entry
     */
    protected handleConvertIntersectionEntry(entry: IntersectionObserverEntry): void {
        const element = entry.target as HTMLVideoElement;

        // Video seems already be converted
        if (!element.matches(this.ui.videoLoader)) {
            this.convertIntersectionObserver?.unobserve(element);
            return;
        }

        if (!entry.rootBounds) {
            return;
        }

        this.convertLoaderToVideo(element);
    }

    /**
     * Handles the intersection for a single intersection entry
     */
    protected handleAutoplayIntersectionEntry(entry: IntersectionObserverEntry): void {
        const element = entry.target as HTMLVideoElement;

        // Video has not the correct class
        if (!element.matches(this.ui.videoPlayer)) {
            this.convertIntersectionObserver?.unobserve(element);
            return;
        }

        if (!entry.rootBounds) {
            return;
        }

        const inView = entry.isIntersecting || entry.intersectionRatio > 0;

        cLog(`Autoplay intersection with inview = ${(inView) ? 'true' : 'false'}`);

        if (inView && element.paused) {
            const playPromise = element.play();
            if (playPromise) {
                playPromise.catch((error) => {
                    if (error.name === 'AbortError') {
                        const retryPlayPromise = element.play();
                        if (retryPlayPromise) {
                            retryPlayPromise.catch((retryError) => {
                                if (retryError.name === 'NotAllowedError') {
                                    this.onAutoplayNotAllowedError(element);
                                }
                                cWarn(retryError);
                            });
                        }
                    } else if (error.name === 'NotAllowedError') {
                        // Maybe because of battery save mode
                        this.onAutoplayNotAllowedError(element);
                    }

                    cWarn(error);
                });
            }
        } else if (!inView && !element.paused) {
            element.pause();
        }
    }

    /**
     * Handles the not allowed error for autoplay videos
     */
    protected onAutoplayNotAllowedError(element: HTMLVideoElement): void {
        const $element = $(element);
        const $container = $element.parent();

        $container.removeClass('is-autoplay');
        $container.addClass('is-autoplay-not-allowed');
        element.removeAttribute('data-autoplay');

        // Disable further intersection observation
        $(element).data('observationDisabled', true);
        this.autoplayIntersectionObserver?.unobserve(element);

        this.bindVideoEvents($container, true);
    }

    /**
     * Converts the loader element to an actual video
     */
    protected convertLoaderToVideo(element: HTMLElement): void {
        if (this.useIntersectionObserver()) {
            this.convertIntersectionObserver?.unobserve(element);
        }

        const $element = $(element);
        const $parent = $element.parent();
        const {success, markup, poster, autoplay} = this.getVideoMarkupConfig(element);

        if (!success || !markup) {
            cWarn('Could not convert video', $element);

            return;
        }

        // Replace the element with the new markup
        $parent.html(markup);

        // Observe element if its an autoplay video
        if (this.useIntersectionObserver()
            && $parent.find(this.ui.autoplayVideo).length > 0
        ) {
            this.autoplayIntersectionObserver?.observe($parent.find(this.ui.autoplayVideo).get(0) as HTMLVideoElement);
        }

        cLog('video converted');

        // Setup and bind listener
        const $videoContainer = $parent.find('.video');

        // Set poster, for autoplay failure
        $videoContainer.find(this.ui.videoPlayer).data({poster, autoplay});

        // Setup listeners
        this.bindVideoEvents($videoContainer);
    }

    /**
     * Returns the video markup for the given video loader element
     */
    protected getVideoMarkupConfig(element: HTMLElement): ConvertedVideo {
        const $element = $(element);
        const autoplay = Number($element.data('autoplay')) === 1;
        const muted = Number($element.data('muted')) === 1;
        let srcData = $element.data('src');

        if (!srcData) {
            cWarn(`No src data found for element`);

            return {success: false};
        }

        // Convert src data
        srcData = JSON.parse(atob(srcData));

        let src = srcData?.filter(item => window.matchMedia(item.media).matches).pop().src;
        if (!src) {
            src = srcData.pop().src;
        }

        cLog(`Found video src = ${src}`);

        // Setup container classes
        const containerClasses = [
            'video',
            autoplay ? 'is-autoplay' : '',
            muted ? 'is-muted' : '',
        ].filter(cls => cls.length > 0).join(' ');

        // Setup video attributes
        const videoAttributes = Object.entries({
            'class': 'video__player',
            'data-autoplay': autoplay ? 'true' : 'false',
            muted: muted,
            loop: autoplay,
            src: src,
            playsinline: true,
            disableremoteplayback: true,
        })
            .filter(([key, value]) => typeof value !== 'boolean' || value)
            .map(([key, value]) => {
                if (typeof value === 'boolean') {
                    return key;
                }
                if (!this.useIntersectionObserver() && key === 'data-autoplay' && value === 'true') {
                    return 'autoplay';
                }
                return `${key}="${value}"`;
            })
            .join(' ');


        let posterMarkup = '';
        let responsiveVideoMissingMarkup = '';
        let playButtonMarkup = '<button type="button" class="video__play-button | js-video-play">Play</button>';

        if ($element.data('poster')) {
            posterMarkup = `<div class="video__poster">${atob($element.data('poster'))}</div>`;
        }

        if ($element.data('responsiveVideoMissing')) {
            responsiveVideoMissingMarkup = `<span class="video__responsive-missing">Responsive video missing. Using original video.</span>`;
        }

        const markup = `
            <div class="${containerClasses}">
                <video ${videoAttributes}></video>
                ${posterMarkup}
                ${playButtonMarkup}
                ${responsiveVideoMissingMarkup}
            </div>
        `;

        return {
            success: true,
            markup,
            autoplay,
            muted,
            src,
        };
    }

    /**
     * Sets up the video for the given video container
     */
    protected bindVideoEvents($videoContainer: Cash, rebind: boolean = false): void {
        const $video = $videoContainer.find(this.ui.videoPlayer);
        const $videoButton = $videoContainer.find(this.ui.videoPlayButton);

        // Check if video was initialized
        if ($video.data('initialized') && !rebind) {
            return;
        }

        // Enable autoplay if no intersectionobserver is supported
        if (!this.useIntersectionObserver()) {
            if ($videoContainer.hasClass('is-autoplay')) {
                $video.prop('autoplay', true);
            }
        }

        // If rebind was triggered, remove previous listeners
        if (rebind) {
            $videoContainer.off();
            $video.off();
        }

        // Attach basic event listener
        $video.on('play', this.onVideoPlay.bind(this));
        $video.on('pause', this.onVideoPause.bind(this));
        $videoButton.on('click', () => {
            // @ts-ignore
            $video.get(0).play();
        });
        $video.data('initialized', true);
    }

    /**
     * Triggered, when a video start to play
     */
    protected onVideoPlay(event: Event): void {
        const $videoContainer = $(event.currentTarget as HTMLVideoElement).parents(this.ui.videoContainer);
        $videoContainer.addClass(this.stateClasses.PLAYING);
        cLog('Video is playing');
    }

    /**
     * Triggered, when a video gets paused
     */
    protected onVideoPause(event: Event): void {
        const $videoContainer = $(event.currentTarget as HTMLVideoElement).parents(this.ui.videoContainer);
        $videoContainer.removeClass(this.stateClasses.PLAYING);
        $videoContainer.addClass(this.stateClasses.PLAYED_BEFORE);
        cLog('Video paused');
    }
}