import $, {Cash} from 'cash-dom';
import {createFocusTrap, FocusTrap} from 'focus-trap';

/**
 * Modal module
 * @package AWW
 * @author Tim Jungmann <Tim@Humans-Machines.com>
 * @author Stefan Rüschenberg <Stefan@Humans-Machines.com>
 */
export default class Modal {

    /**
     * Cash body reference
     */
    protected $body: Cash;

    /**
     * UI references
     */
    protected ui = {
        modal: '.js-modal',
        activeModal: '.js-modal.is-active',
        modalBtn: '.js-modal-btn',
        modalBackdrop: '.js-modal-backdrop',
        menuModal: '.js-main-menu',
        menuModalFocusWrapper: '.js-modal-focus-trap',
    };

    /**
     * Internal modal opened state
     */
    protected isModalOpened: boolean = false;

    /**
     * Focus trap for opened menu
     */
    protected focusTrap: FocusTrap | null = null;

    /**
     * Last active element before mobile nav was opened
     */
    protected lastActiveElement: HTMLElement | Element | null = null;

    /**
     * Getter method to check if modal is opened
     */
    get modalOpened(): boolean {
        return this.isModalOpened || $(this.ui.activeModal).length > 0;
    }

    /**
     * Sets the modal opened state
     */
    set modalOpened(state: boolean) {
        this.isModalOpened = state;
        this.$body.toggleClass('has-modal', this.modalOpened);
    }

    /**
     * Default modal constructor
     */
    public constructor() {
        this.$body = $('body');
        this.setScrollbarWidth();
        this.registerEvents();
    }

    /**
     * Register required browser and PubSub events
     */
    protected registerEvents(): void {
        this.$body.on('click', this.ui.modalBtn, this.onModalTriggerClick.bind(this));
        this.$body.on('click', this.ui.modalBackdrop, this.onModalTriggerClick.bind(this));
        this.$body.on('keydown', this.onCaptureKeyup.bind(this));
    }

    /**
     * Sets the calculated scrollbar width as custom property on the document element
     */
    protected setScrollbarWidth(): void {
        const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
        document.documentElement.style.setProperty('--scrollbar-w', `${scrollbarWidth}px`);
    }

    /**
     * Opens the modal that is passed as parameter
     */
    protected openModal($modal: Cash): void {
        $modal.addClass('is-active is-scrollable');
        this.modalOpened = true;

        // Toggle aria attr on button
        $modal.find(this.ui.modalBtn).attr('aria-expanded', 'true');

        // Add class to body
        if ($modal.data('modalName')) {
            this.$body.addClass(`has-${$modal.data('modalName')}`);
        }

        // Store last active element for refocus after close
        this.lastActiveElement = document.activeElement;

        // Add focus trap
        if($modal.parents(this.ui.menuModalFocusWrapper).length) {
            // Add to parent element if suitable is available
            this.focusTrap = createFocusTrap($(this.ui.menuModalFocusWrapper).get(0) as HTMLElement);
        } else {
            this.focusTrap = createFocusTrap($modal.get(0) as HTMLElement);
        }
        this.focusTrap.activate();
    }

    /**
     * Closes the given modal
     */
    protected closeModal($modal: Cash): void {
        // Add transition end listener to do some cleanup work
        $modal.one('transitionend', () => {
            // Set modal opened state to prevent double scrollbars
            setTimeout(() => {
                // Remove main modal class if appropreate
                this.modalOpened = $(this.ui.activeModal).length > 0;

                // Remove scrolling class
                $modal.removeClass('is-scrollable');
            }, 1);

            // Re-focus last active element
            if (this.lastActiveElement && 'focus' in this.lastActiveElement) {
                this.lastActiveElement.focus();
                this.lastActiveElement = null;
            }
        });

        // Toggle aria attr on button
        $modal.find(this.ui.modalBtn).attr('aria-expanded', 'false');

        // Remove active state class from modal
        $modal.removeClass('is-active');

        // Remove class name
        if ($modal.data('modalName')) {
            this.$body.removeClass(`has-${$modal.data('modalName')}`);
        }

        // Disable focus trap
        this.focusTrap?.deactivate();
        this.focusTrap = null;
    }

    /**
     * Handles the click event for toggle modal triggers
     */
    protected onModalTriggerClick(event: PointerEvent): void {
        event.preventDefault();

        const $target = $(event.currentTarget as HTMLElement);
        const $modal = $target.parents(this.ui.modal);

        if (!this.modalOpened) {
            this.openModal($modal);
        } else {
            this.closeModal($modal);
        }
    }

    /**
     * Captures the keyup event from the browser
     * If modal is opened and ESC is pressed, we will close the current modal
     */
    protected onCaptureKeyup(event: KeyboardEvent): void {
        const $menuModal = $(this.ui.menuModal);

        // Handle cmd + k to open menu
        if ((event.metaKey || event.ctrlKey) && event.key === 'k' || event.key === 'K') {
            if (!this.modalOpened) {
                event.preventDefault();

                this.openModal($menuModal);
            }
            // Handle close request
        } else if (event.key === 'Escape') {
            if (!this.modalOpened) {
                return;
            }

            event.preventDefault();
            this.requestModalClose();
        }
    }

    /**
     * Requests a modal to be closed
     */
    protected requestModalClose($modal?: Cash): void {
        let $relatedModal = $modal ?? this.getActiveModal();
        this.closeModal($relatedModal);
    }

    /**
     * Returns the current active modal
     */
    protected getActiveModal(): Cash {
        return $(this.ui.activeModal);
    }
}